.header a {
  color: black;
}

.header {
  position: relative;
  background-color: #ffffff;
}

.header .hidemobile {
  display: block;
}

.header .showmobile {
  display: none;
}

.header-open {
  position: absolute;
  top: 28px;
  left: 20px;
  cursor: pointer;
  display: none;
}

.header-close {
  color: grey;
  font-weight: 100;
  font-size: 25px;
  position: absolute;
  top: 28px;
  left: 20px;
  cursor: pointer;
  display: none;
}

.header-open > div {
  width: 25px;
  height: 1px;
  background-color: #FFFFFF;
  margin: 4px 0;
}

.header .header-nav {
  margin: 0px auto;
  padding: 50px 0px 0px 0px;
  font-weight: 500;
  font-family: Montserrat;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.header .header-nav .nav-item {
  flex-grow: 1;
  transform: scale(1, 1.1);
}

.header .header-nav .nav-item:hover {
  cursor: pointer;
  opacity: .7;
}

.header .header-nav .logo-nav {
  flex-grow: 2;
  width: 30%;
  margin-top: -10px;
}

.header .header-nav .logo-nav .logo {
  width: 126px;
  height: auto;
  transform: scale(1, 1) !important;
}

.header .header-nav .buy-tickets-button {
  background-color: #000000;
  padding: 10px 20px;
  font-weight: 500;
  align-self: auto;
  color: white !important;
  font-size: 12px;
  letter-spacing: 1px;
  margin-left: 20px;
}

.banner {
  color: white;
  background-color: #d11f34;
  font-family: Spartan;
  text-align: center;
  padding: 10px;
}

.learn-more-link:hover {
  opacity: 0.8;
  cursor: pointer;
}

.learn-more-link {
  text-decoration: underline;
}

.note-content {
  padding: 40px;
  font-size: 30px;
  line-height: 40px;
  text-align: justify;
  font-family: 'Dancing Script', cursive;
}

.note-content a {
  text-decoration: underline;
}

.tickets-banner {
  color: white;
  background-color: #d11f34;
  font-family: Spartan;
  text-align: center;
  padding: 10px;
}

@media (max-width: 1000px) {
  .header .header-nav .logo-nav .logo {
    width: 100px;
  }

  .header .header-nav .logo-nav {
    width: 30%;
  }

  .header .header-nav {
    font-size: 14px;
  }

  .header .header-nav .buy-tickets-button {
    padding: 8px 13px;
    font-size: 12px;
    margin-left: 10px;
  }
}

@media (max-width: 800px) {
  .header {
    height: auto;
    text-align: center;
    padding: 0px 0px 10px 0px;
  }

  .header .showmobile {
    display: block;
  }

  .header .hidemobile {
    display: none;
  }

  .header.expanded {
    z-index: 3;
  }

  .expanded .fade-content {
    background: rgba(0, 0, 0, 0.3);
    width: 20%;
    height: 110vh;
    margin: -20px 0px 0px -20px;
    position: fixed;
    z-index: 1;
  }

  .header .header-nav {
    flex-direction: column;
    text-align: center;
    background-color: white;
    width: 90%;
    float: right;
    height: 100vh;
    position: fixed;
    margin-left: 20%;
    z-index: 2;
    box-shadow: -5px 0px 50px 5px grey;
    padding: 120px 30px 0px 0px;
  }

  .header .header-nav .buy-tickets-button {
    margin-right: 5px;
    background-color: #d11f34;
    padding: 15px 20px;
    font-size: 16px;
  }

  .header .header-nav .MuiBox-root-8 {
    margin-top: 10px;
  }

  .header img.logo {
    margin: 15px 0px 0px 20px;
    width: 115px;
    height: auto;
  }

  .header img.logo.showmobile {
    float: left;
  }

  .header.collapsed .header-close {
    display: none;
  }

  .header.collapsed .header-open {
    display: block;
    width: 90%;
  }

  .header.collapsed .header-open .menu-icon {
    float: right;
  }

  .header.collapsed .header-nav {
    display: none;
  }

  .header.expanded .header-nav {
    display: block;
  }

  .header.expanded .header-close {
    display: inline-block;
    text-align: right;
    width: 70%;
    margin-top: 40px;
    font-family: Helvetica;
    transform: scale(1.2, 1);
    color: black;
  }

  .header.expanded .header-open {
    display: none;
  }

  .header-nav .banner-nav-link img {
    display: none;
  }

  .header-border-wrapper {
    top: 73px;
  }

  .header.expanded .nav-item {
    padding: 15px;
    text-transform: uppercase;
  }

  .header.expanded .nav-item:first-child {
    margin-top: 100px;
  }
}
