/* Universal element styling */
* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -moz-tap-highlight-color: rgba(0, 0, 0, 0);
}

a {
  transition: opacity 300ms ease;
  text-decoration: none;
  color: #d11f34;
}

a:hover {
  opacity: .7;
}

h1 {
  font-size: 70px;
  color: black;
  margin: 130px 0px 50px 0px;
  letter-spacing: 2px;
  font-weight: 900;
  font-family: Spartan;
  line-height: 90px;
  letter-spacing: 1px;
  width: 38%;
  text-align: left;
}

h2 {
  font-size: 32px;
  color: #d11f34;
  font-family: Spartan;
  font-style: normal;
  font-weight: 700;
}

h3 {
  font-size: 22px;
  color: #d11f34;
  font-family: Spartan;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -1px;
  text-transform: uppercase;
}

h4 {
  color: black;
  font-family: Montserrat;
  transform: scale(1, 1.1);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

/* Page structure */
.full-width {
  width: 101%;
  margin-left: -5px;
}

.page-body {
  min-height: 500px;
  background-color: #d50032;
}

.container {
  max-width: 1150px;
  width: 95%;
  margin: auto;
}

.flex-row {
  display: flex;
}

.flex-item {
  flex: 1;
}

.flex-half {
  flex: 0 0 50%;
}

.static-page {
  margin: 0px auto;
  max-width: 1150px;
  width: 95%;
}

.static-page .container {
  width: 85%;
  margin: 0px auto;
}

.header-description {
  margin-bottom: 70px;
  font-size: 17px;
  letter-spacing: 0px;
  line-height: 25px;
  margin-top: -5px;
  font-weight: 300;
  transform: scale(1, 1.055);
  font-family: Montserrat;
  text-align: left;
}

.showmobile {
  display: none !important;
}

/* Background colors */
.pink-background {
  background-color: #d51f35;
}

.grey-background {
  background-color: #f5f5f5;
}

.purple-background {
  background-color: #271c21;
}

/* Text styling */
.tiny-text {
  letter-spacing: 1px;
  font-size: 11px;
  font-family: Montserrat;
}

.disclaimer {
  font-size: 12px;
  color: black;
  font-family: Spartan;
  font-style: normal;
  font-weight: 400;
}

.underline {
  border-bottom: 1px solid white;
}

.text-underline {
  text-decoration: underline;
}

.padding-bottom {
  padding-bottom: 15px;
}

strong {
  font-weight: 500 !important;
}

.italics {
  font-style: italic;
}

.hidedesktop {
  display: none;
}

.hidemobile {
  display: block;
}

.bar {
  position: fixed;
  display: flex;
  bottom: 0;
  width: 100%;
  background-color: black;
  color: white;
  justify-content: center;
  align-items: center;
  height: 50px;
  z-index: 99;
}

.bar .cookies-button {
  border: 2px solid white;
  padding: 5px;
}

.bar .cookies-button:hover {
  cursor: pointer;
  opacity: 0.8;
}

.bar .privacy-link {
  color: white !important;
  text-decoration: underline;
}

.bar > div {
  margin: auto;
}

/* Buttons */
.cta-button {
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 20px;
  font-weight: 500;
  font-family: Montserrat;
  transform: scale(1, 1.1) !important;
  font-size: 16px;
  border: 1px solid #f3f3f3;
  background-color: #d11f34;
  color: white !important;
  text-decoration: none !important;
  cursor: pointer;
}

.cta-slim-button {
  max-height: 44px;
  max-width: 100%;
  cursor: pointer;
}

.cta-button:hover,
.cta-slim-button:hover {
  opacity: .9;
}

.cta-button a:hover,
.cta-slim-button a:hover {
  opacity: 1;
}

.large-cta {
  background-color: #271c21;
  color: #FFFFFF;
  text-align: center;
  text-transform: uppercase;
  font-weight: 100;
  padding: 14px 0px;
  font-size: 22px;
  border: 0px;
  display: block;
  width: 100%;
  letter-spacing: 3px;
  margin: 20px 0px 40px 0px;
}

.small-cta {
  background-color: #d11f34;
  color: #FFFFFF;
  text-align: center;
  text-transform: uppercase;
  font-weight: 50;
  padding: 15px 10px;
  font-size: 10px;
  border: 0px;
  display: block;
  letter-spacing: 3px;
}

.small-cta:hover {
  opacity: .8;
  cursor: pointer;
}

.dialog-close {
  position: absolute;
  top: 16px;
  right: 20px;
  color: #939598 !important;
  font-weight: 100;
  cursor: pointer;
}

/* Form */
.MuiInput-input {
  font-family: Montserrat;
  transform: scale(1, 1.055);
  font-weight: 400;
  padding-top: 30px;
}

.form-label {
  font-family: Spartan !important;
  text-transform: uppercase !important;
  letter-spacing: 1px !important;
  transform: scale(1, 1.055) !important;
  font-size: 14px !important;
  color: #000000 !important;
  font-weight: 700 !important;
  padding-top: 20px !important;
}

.MuiFormControlLabel-label {
  font-family: Spartan;
  text-transform: uppercase;
  transform: scale(1, 1.055);
  font-size: 14px;
  color: #000000;
  font-weight: 700;
}

.payment-form {
  width: 85%;
}

@media (max-width: 1000px) {

  .payment-form {
    width: 100%;
  }

  .hidemobile {
    display: none;
  }

  .hidedesktop {
    display: block;
  }

}

@media (max-width: 800px) {

  .static-page {
    margin: 0px auto;
    width: 85%;
  }

  .static-page .container {
    width: 100%;
    margin: 0px auto;
  }

  .showmobile {
    display: inline-block !important;
  }

  h1 {
    font-size: 36px;
    line-height: 42px;
    width: 100%;
    letter-spacing: -1px;
  }

  h2 {
    margin: -30px 0px 50px 0px;
    font-size: 20px;
    width: 100%;
    letter-spacing: -1px;
  }

  h3 {
    font-size: 17px;
  }

  h4 {
    font-size: 14px;
  }

  .header-description {
    margin-bottom: 50px;
    font-size: 13px;
    line-height: 19px;
    margin-top: -25px;
    text-align: justify;
  }

  .form-label {
    font-size: 12px !important;
  }

  .large-cta {
    font-size: 16px;
  }

  .bar {
    font-size: 12px !important;
    font-size: 14px;
    padding: 10px;
    height: 70px;
  }

  .bar .cookies-button {
    border: 2px solid white;
    padding: 5px;
    width: 45%;
    margin-left: 5px;
    text-align: center;
    max-width: 80px;
  }
}

/* auth styling */
.auth0-lock.auth0-lock.auth0-lock-opened .auth0-lock-widget {
  width: 95%;
  max-width: 550px;
}

.auth0-lock-header-avatar {
  display: none !important;
}

.auth0-lock.auth0-lock .auth0-lock-tabs-container {
  display: none;
}

.auth0-lock.auth0-lock .auth0-lock-cred-pane {
  border-radius: 0px !important;
}

.auth0-lock-header {
  display: none !important;
}

.auth0-lock.auth0-lock .auth0-lock-close-button,
.auth0-lock.auth0-lock .auth0-lock-back-button {
  background: transparent !important;
  border: 0px !important;
}

.auth0-lock.auth0-lock .auth0-lock-tabs {
  text-transform: uppercase;
}

.auth0-lock.auth0-lock .auth0-global-message.auth0-global-message-error {
  background-color: #d50032 !important;
}

.auth0-lock-input-block.auth0-lock-input-email:before {
  content: 'Welcome back!';
  text-align: center;
  font-size: 22px;
  display: block;
  margin: 50px auto 15px auto;
  font-family: Spartan !important;
  font-weight: 800 !important;
}

.auth0-lock-close-button {
  box-shadow: none !important;
  top: 5px !important;
  right: 5px !important;
}

.auth0-lock.auth0-lock .auth0-lock-input-wrap.auth0-lock-input-wrap-with-icon {
  height: 40px !important;
  width: 95% !important;
  max-width: 350px !important;
  margin: auto !important;
  border: 0px !important;
  text-align: left !important;
}

.auth0-lock.auth0-lock .auth0-lock-form .auth0-lock-alternative {
  margin-bottom: -20px !important;
}

.auth0-lock-input-wrap svg {
  display: none !important;
}

.auth0-lock.auth0-lock .auth0-lock-input-wrap.auth0-lock-input-wrap-with-icon {
  padding-left: 0px !important;
}

.auth0-lock.auth0-lock .auth0-lock-input-wrap .auth0-lock-input {
  margin: auto !important;
  font-weight: 700;
  border-radius: 0px !important;
  font-family: Spartan !important;
  color: #a1a1a1 !important;
  background-color: #f2f2f2 !important;
}

.auth0-lock-alternative-link {
  font-family: Montserrat !important;
  font-weight: 500 !important;
  color: #a3a3a3 !important;
  font-size: 14px !important;
}

.auth0-lock.auth0-lock .auth0-lock-input-wrap .auth0-lock-input::placeholder {
  color: #a1a1a1 !important;
  font-weight: 700;
}

.auth0-lock.auth0-lock .auth0-lock-submit {
  background-size: cover;
  border-radius: 0px !important;
  height: 40px !important;
  padding: 0px !important;
  margin: 30px auto 40px auto !important;
  font-weight: 200 !important;
  font-size: 12px !important;
  box-shadow: none !important;
  max-width: 350px !important;
  width: 100% !important;
}

.auth0-lock-submit svg {
  display: none !important;
}

/* Checkout */
.apple-pay-form .StripeElement {
  box-shadow: 0px 0px 5px 1px #888888;
  background-color: black;
}

.credit-card-form .StripeElement {
  box-shadow: 0px 0px 5px 1px #888888;
  padding: 10px;
  background-color: white;
}
