.wrapper {
  width: 100%;
}

.landing-page h1 {
  padding-bottom: 10px;
  width: 120%;
}

.landing-page .header-description.title-description {
  text-transform: none !important;
  width: 80%;
  font-size: 20px;
  margin-top: -40px;
  margin-bottom: 50px;
}

.landing-page .logos {
  z-index: 1;
  margin-left: -20px;
  margin-top: -10px;
}

.landing-page .logos .logos-text {
  font-family: Spartan;
  padding-left: 20px;
  font-weight: 400;
  color: #cccccc;
}

.landing-page .logos .logos-flex {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.landing-page .company-logo {
  width: 150px;
  height: auto;
  padding: 20px;
  z-index: 1;
}

.landing-page .cta-button.top-button span {
  letter-spacing: 2px;
  padding: 20px;
  font-size: 16px;
}

.landing-page .top-flex {
  display: flex;
  margin-right: -100px;
  width: 92%;
  flex-direction: row;
  align-items: flex-end;
}

.landing-page .title-section {
  width: 60%;
  align-self: flex-start;
}

.landing-page .cta-video {
  width: 40%;
  padding-top: 250px;
}

/* Collage */
.landing-page .collage {
  flex: 1;
  width: 57%;
  padding-top: 50px;
  margin-left: -30px;
}

.landing-page .image-stack {
  display: grid;
  grid-template-columns: auto;
  position: relative;
  width: 90%;
  z-index: -1;
}

.landing-page .image-stack__item .image {
  box-shadow: 20px 20px 15px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.landing-page .theater-image-1 {
  background: url("/src/images/landing collage/image_17.webp") 85% 40% no-repeat;
  background-size: 170%;
  width: 275px;
  height: 246px;
  border-radius: 10%;
}

.landing-page .theater-image-2 {
  background: url("/src/images/landing collage/west end.webp") 50% 50% no-repeat;
  background-size: 150%;
  width: 550px;
  height: 430px;
  border-radius: 3%;
}

.landing-page .theater-image-4 {
  background: url("/src/images/landing collage/bus.webp") 50% 50% no-repeat;
  background-size: 175%;
  width: 256px;
  height: 246px;
  border-radius: 10%;
}

.landing-page .image-container-1 {
  grid-column: 1 / 12;
  grid-row: 12 / 21;
  z-index: 1;
}

.landing-page .image-container-2 {
  grid-column: 7 / 28;
  grid-row: 5 / 27;
}

.landing-page .image-container-4 {
  grid-column: 25 / 30;
  grid-row: 1 / 11;
  z-index: 1;
}

/* Carousel */
.landing-page .playbill-photos {
  width: 100%;
  max-width: 1600px;
  margin: 0px auto;
  background-color: #f3f3f3;
}

.landing-page .carousel {
  width: 90%;
  margin: 100px auto 20px;
  padding: 40px 20px;
}

.react-multi-carousel-item img {
  width: 90%;
  height: auto;
  padding: 10px;
  margin: 0px auto;
}

.landing-page .react-multiple-carousel__arrow {
  z-index: 2 !important;
}

@media (max-width: 1360px) {
  .landing-page .theater-image-1 {
    background: url("/src/images/landing collage/image_17.webp") 85% 40% no-repeat;
    background-size: 170%;
    width: 225px;
    height: 201px;
    border-radius: 10%;
  }

  .landing-page .theater-image-2 {
    background: url("/src/images/landing collage/west end.webp") 35% 50% no-repeat;
    background-size: 150%;
    width: 500px;
    height: 336px;
    border-radius: 3%;
  }

  .landing-page .theater-image-4 {
    background: url("/src/images/landing collage/bus.webp") 30% 0% no-repeat;
    background-size: 175%;
    width: 206px;
    height: 198px;
    border-radius: 10%;
  }

  .landing-page .logos {
    z-index: 1;
    margin-left: -20px;
    margin-top: 50px;
  }
}

@media (max-width: 1165px) {
  .landing-page h1 {
    font-size: 60px;
    line-height: 70px;
  }

  .landing-page .theater-image-1 {
    background: url("/src/images/landing collage/image_17.webp") 85% 40% no-repeat;
    background-size: 170%;
    width: 175px;
    height: 156px;
    border-radius: 10%;
  }

  .landing-page .theater-image-2 {
    background: url("/src/images/landing collage/west end.webp") 35% 50% no-repeat;
    background-size: 150%;
    width: 450px;
    height: 302px;
    border-radius: 3%;
  }

  .landing-page .theater-image-4 {
    background: url("/src/images/landing collage/bus.webp") 30% 0% no-repeat;
    background-size: 175%;
    width: 156px;
    height: 150px;
    border-radius: 10%;
  }
}

@media (max-width: 1050px) {
  .landing-page h1 {
    width: 100%;
    font-size: 50px;
    line-height: 60px;
    margin: 50px 0px 50px 0px;
  }

  .landing-page .logos {
    z-index: 1;
    margin-left: -20px;
  }

  /* Carousel */
  .landing-page .carousel {
    width: 100%;
    margin: 120px auto 20px;
  }
}

@media (max-width: 1020px) {
  .landing-page .theater-image-1 {
    background: url("/src/images/landing collage/image_17.webp") 85% 40% no-repeat;
    background-size: 170%;
    width: 125px;
    height: 111px;
    border-radius: 10%;
  }

  .landing-page .theater-image-2 {
    background: url("/src/images/landing collage/west end.webp") 35% 50% no-repeat;
    background-size: 150%;
    width: 400px;
    height: 268px;
    border-radius: 3%;
  }

  .landing-page .theater-image-4 {
    background: url("/src/images/landing collage/bus.webp") 30% 0% no-repeat;
    background-size: 175%;
    width: 106px;
    height: 102px;
    border-radius: 10%;
  }

  .landing-page .logos {
    z-index: 1;
    margin-left: -20px;
    margin-top: 50px;
  }
}

@media (max-width: 800px) {
  .hidemobile {
    display: block;
  }

  .hidedesktop {
    display: none;
  }

  .landing-page h1 {
    width: 100%;
    font-size: 40px;
    line-height: 50px;
  }

  .landing-page .theater-image-1 {
    background: url("/src/images/landing collage/image_17.webp") 85% 40% no-repeat;
    background-size: 170%;
    width: 150px;
    height: 133px;
    border-radius: 10%;
  }

  .landing-page .theater-image-2 {
    background: url("/src/images/landing collage/west end.webp") 35% 50% no-repeat;
    background-size: 150%;
    width: 325px;
    height: 218px;
    border-radius: 3%;
  }

  .landing-page .image-container-1 {
    grid-column: 1 / 14;
    grid-row: 1 / 13;
    z-index: 1;
  }

  .landing-page .image-container-4 {
    grid-column: 25 / 30;
    grid-row: 1 / 14;
    z-index: 1;
  }

  .landing-page .theater-image-4 {
    display: none;
  }
}

@media (max-width: 750px) {
  .hidemobile {
    display: none;
  }

  .hidedesktop {
    display: block;
  }

  .landing-page .title-section {
    width: 100%;
  }

  .landing-page h1 {
    font-size: 50px;
    line-height: 60px;
    width: 100%;
  }

  /** Collage **/
  .landing-page .image-stack-mobile {
    display: grid;
    position: relative;
    width: 75%;
    margin: 50px auto;
  }

  .landing-page .image-container-1 {
    grid-column: 1 / 10;
    grid-row: 4 / 9;
    z-index: 2;
  }

  .landing-page .image-container-2 {
    grid-column: 8 / 12;
    grid-row: 7 / 10;
    z-index: 2;
  }

  .landing-page .image-container-4 {
    grid-column: 6 / 15;
    grid-row: 1 / 6;
  }

  .landing-page .theater-image-1 {
    background: url("/src/images/landing collage/west end.webp") 45% 65% no-repeat;
    background-size: 300%;
    width: 400px;
    height: 300px;
    border-radius: 10%;
  }

  .landing-page .theater-image-2 {
    background: url("/src/images/landing collage/image_17.webp") 75% 40% no-repeat;
    background-size: 200%;
    width: 175px;
    height: 175px;
    border-radius: 10%;
  }

  .landing-page .theater-image-4 {
    background-size: 200%;
    width: 225px;
    height: 225px;
    border-radius: 10%;
    display: block;
    z-index: 1;
  }

  .landing-page .logos .logos-text {
    text-align: center;
    margin-top: 10px;
  }

  .landing-page .logos .logos-flex {
    justify-content: center;
  }

  .landing-page .playbill-photos {
    margin-top: -50px;
  }
}

@media (max-width: 500px) {
  .landing-page h1 {
    font-size: 40px;
    line-height: 50px;
    width: 100%;
  }

  .landing-page .logos {
    margin: 50px auto;
    position: absolute;
    width: 85%;
  }

  .landing-page .logos .logos-text {
    font-size: 12px;
    margin-top: -50px;
  }

  .landing-page .company-logo {
    width: 100px;
    padding: 10px;
  }

  .landing-page .playbill-photos {
    margin-top: 40px;
  }

  .landing-page .theater-image-1 {
    background: url("/src/images/landing collage/west end.webp") 45% 65% no-repeat;
    background-size: 300%;
    width: 225px;
    height: 175px;
    border-radius: 10%;
  }

  .landing-page .theater-image-2 {
    background: url("/src/images/landing collage/image_17.webp") 75% 40% no-repeat;
    background-size: 200%;
    width: 125px;
    height: 125px;
    border-radius: 10%;
  }

  .landing-page .theater-image-4 {
    background-size: 200%;
    width: 125px;
    height: 125px;
    border-radius: 10%;
    display: block;
    z-index: 1;
  }

  .landing-page .image-container-4 {
    grid-column: 7 / 15;
    grid-row: 1 / 6;
  }

  .landing-page .image-stack-mobile {
    width: 90%;
  }
}

@media (max-width: 350px) {
  .landing-page .image-stack-mobile {
    width: 100%;
  }
}
